import React from "react";
import  { getDate } from '../../utilities/utilfns';
import "./index.css";


function Blog(props){
    const {blog = {}} = props;

    return (
        <a href={blog.url}  className="also-read-blog"> 
            <div className="also-read-blog-title"> {blog.title} </div>
            <div className="also-read-blog-subTitle"> {blog.subTitle.subTitle} </div>
            <div className="also-read-blog-updatedAt">  {getDate(new Date(blog.updatedAt))} </div>
        </a>);
}

export default Blog;