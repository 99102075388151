import React, { Fragment }  from "react";
import './index.css';

function BreadCrumbs(props){

    const {location} = props;
    const {origin = "", pathname = ""} = location;
    const pages = pathname.replaceAll("/", " ").trim().split(" ");
    
    return pages && pages.length > 0 ? <div className="breadcrumb-container"> 
        {pages.map((ele, ind) => <Fragment> <a className="breadcrumb"  href={ origin  + pathname.split(ele)[0] + ele} >  {ele.length == 0 ? "Home" : ele}  </a>  
            {ind < pages.length-1 ? <div  className="next-icon"/>  : null }  </Fragment> )}
    </div> : null;

}

export default BreadCrumbs;