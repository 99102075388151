import React , {useEffect , useState , Fragment} from "react";
import { graphql } from "gatsby";
import Layout from '../../components/layout/index';
import BreadCrumbs from "../../components/breadCrumbs/index";
import Blog from "../../components/blog/index";
import "./index.css"


const simplyFiBlogIntro = "Welcome to the Simplyfi Blog Series, your go-to destination for simplifying finance! Dive into our series to discover expert insights, practical tips, and invaluable resources designed to demystify complex financial topics. From budgeting basics to investment strategies, each blog post is crafted to empower you with the knowledge and confidence to take control of your finances. Whether you're a seasoned investor or just starting your financial journey, our series offers something for everyone. Get ready to embark on a journey of financial empowerment and join us as we simplify finance, one blog post at a time. Welcome aboard!";


function BlogsList({data}){
    const {allContentfulSimplyFiBlogPage = {}} = data;
    const [location, setLocation] = useState({});
    const [allBlogs , setBlogs] = useState([])


    useEffect( () => {
        setLocation(global.location);
        setBlogs((allContentfulSimplyFiBlogPage.nodes || []).map(ele => ({...ele , url :  global.location.href + "/" + ele.slug}) ));
        // setAlsoRead( alsoread.map(ele => basePath + ele) );
      } , [global.location ] )

    return (
    <Layout>
        <div className="blogs-container">
            <BreadCrumbs location={location} key={location.pathname}/>
            <div style={{color : "#333", fontSize : "300%", fontWeight:700}}> {"Simplyfi Blogs"} </div>
            <div style={{margin : "1.5rem 0", color : ""}}> {simplyFiBlogIntro} </div>
            <div className="all-blogs-container">
                {allBlogs.map(ele => <Blog blog={ele}/>)}
            </div>
        </div> 
    </Layout>);
}


export default BlogsList;


export const Head = ({data}) => {
    const {contentfulSimplyFiBlogPage = {}} = data;

    return (
    <Fragment>
        <title> { "SimplyFi Blogs"  } </title>
        {/* <meta name="description"  content={content} /> */}
    </Fragment>)
}

export const query = graphql` 
query MyQuery {
    allContentfulSimplyFiBlogPage {
      nodes {
        slug
        subTitle {
          subTitle
        }
        title
        updatedAt
      }
    }
  }
  `
